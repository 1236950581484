import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import Bg from '../images/shapes/bg_pattern_3.svg'
import Bg2 from '../images/shapes/shape_space_6.svg'
import icon1 from '../images/shapes/shape_space_2.svg'
import icon2 from '../images/icons/icon_mail_2.svg'
import logo from '../images/img/logo-productos-el-roble-logo-productos-el-roble-fabrication-and-welding-w.png'
import logo2 from '../images/img/logo-productos-el-roble-logo-productos-el-roble-fabrication-and-welding.png'
import logo3 from '../images/img/roble_web1.png'
import Slider from "react-slick";


export function Widgets() {
    return (
        <div>Widgets</div>
    )
}

export function Header() {

    const [mobailActive, setMobailState] = useState(false);

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const [isSticky, setSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 80) {
                setSticky(true);
            } else {
                setSticky(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (

        <header className="site_header site_header_1">
            <div className="header_top text-center">
                <div className="container">
                    <p className="m-0"> <b>El Roble</b> – Precision Material Cutting and Transformation Center</p>
                </div>
            </div>
            <div className={`header_bottom stricky  ${isSticky ? 'stricked-menu stricky-fixed' : ''}`}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-3 col-lg-2 col-5">
                            <div className="site_logo" style={{marginLeft:'60px'}}>
                                <Link onClick={ClickHandler} className="site_link" to="/">
                                    <img src={logo} alt="" />
                                    <img src={logo2} alt="" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-7 col-2">
                            <nav className="main_menu navbar navbar-expand-lg">
                                <div className="main_menu_inner collapse navbar-collapse justify-content-lg-center" id="main_menu_dropdown">
                                    <ul className="main_menu_list unordered_list justify-content-center">
                                        <li className="">
                                            <Link onClick={ClickHandler} className="nav-link" to="/" id="home_submenu" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Home
                                            </Link>
                                        </li>
                                        <li className="">
                                            <a onClick={ClickHandler} className="nav-link" id="company_submenu" role="button" data-bs-toggle="dropdown" aria-expanded="false" href='#about'>
                                                About Us 
                                            </a>
                                        </li>
                                        <li className="">
                                            <a onClick={ClickHandler} className="nav-link" id="services_submenu" role="button" data-bs-toggle="dropdown" aria-expanded="false" href='#services'>
                                                Services
                                            </a>
                                        </li>
                                        <li className="">
                                            <a onClick={ClickHandler} className="nav-link" id="pages_submenu" role="button" data-bs-toggle="dropdown" aria-expanded="false" href='#working'>
                                            Work with us 
                                            </a>
                                        </li>
                                        <li><a onClick={ClickHandler} href='#contact'>Contact</a></li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-5">
                            <ul className="header_btns_group unordered_list justify-content-end">
                                <li>
                                    <button className="mobile_menu_btn" onClick={() => setMobailState(!mobailActive)} type="button" data-bs-toggle="collapse" data-bs-target="#main_menu_dropdown" aria-expanded="false" aria-label="Toggle navigation">
                                        <i className="far fa-bars"></i>
                                    </button>
                                </li>
                                <li>
                                    <a onClick={ClickHandler} className="btn btn-primary" href='#contact'>
                                        <span className="btn_label" data-text="GET A QUOTE">GET A QUOTE</span>
                                        <span className="btn_icon">
                                            <i className="fa-solid fa-arrow-up-right"></i>
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="mobail-wrap">
                    <div className={`mobail-menu ${mobailActive ? "active" : ""}`}>
                        <div className="xb-header-menu-scroll">
                            <div className="xb-menu-close xb-hide-xl xb-close" onClick={() => setMobailState(!mobailActive)}></div>
                            <nav className="xb-header-nav">
                                {/* <MobileMenu /> */}
                            </nav>
                        </div>
                    </div>
                    <div className="xb-header-menu-backdrop" onClick={() => setMobailState(false)}></div>
                </div>
            </div>
        </header>

    )
}

export function Footer() {
        const ClickHandler = () => {
        window.scrollTo(10, 0);
        }
        
        const SubmitHandler = (e) => {
            e.preventDefault()
        }
        
        var settings = {
            dots: false,
            infinite: true,
            speed: 3000,
            slidesToShow: 5,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 0,
            cssEase: "linear",
            arrows: false,
        
            responsive: [
            {
                breakpoint: 1025,
                settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                }
            },
            {
                breakpoint: 991,
                settings: {
                slidesToShow: 3,
                slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                slidesToShow: 3,
                slidesToScroll: 1
                }
            },
            {
                breakpoint: 600,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 1
                }
            },
            {
                breakpoint: 450,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 1
                }
            },
            {
                breakpoint: 350,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1
                }
            }
            ]
        };

    return(
        <div>
            <footer className="site_footer footer_layout_2 section_decoration" style={{ backgroundImage: `url(${Bg})`, paddingTop:'90px' }}>
                <div className="decoration_item shape_image_1">
                    <img src={icon1} alt="Techco Shape" />
                </div>
                <div className="container">
                    <div className="footer_main_content">
                    <div className="row justify-content-lg-between">
                        <div className="col-lg-2 col-md-6">
                        <div className="footer_widget">
                            <img src={logo3} alt="" />
                        </div>
                        </div>
                        <div className="col-lg-7 col-md-6">
                        <div className="footer_widget">
                            <h3 className="footer_info_title">About Us</h3>
                            <p>At El Roble, we specialize in precision material cutting and
                            transformation, providing innovative and reliable solutions
                            tailored to our clients' needs. With a commitment to quality,
                            efficiency, and advanced technology, we ensure every
                            project is delivered to the highest standards, driving success for industries</p>
                        </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                        <div className="footer_widget">
                            <h3 className="footer_info_title">Contact Us</h3>
                                <ul className="icon_list unordered_list_block text-white">
                                    <li>
                                        <span className="icon_list_text">
                                            <i className="fa-solid fa-phone-volume"></i> +1 713 283-3067
                                        </span>
                                    </li>
                                    <li>
                                        <span className="icon_list_text">
                                            <i className="fa-solid fa-phone-volume"></i> +52 55 5648-1436
                                        </span>
                                    </li>
                                    <li>
                                        <span className="icon_list_text">
                                            <i className="fa-solid fa-envelope"></i>  salesusa@roblemx.com
                                        </span>
                                    </li>
                                </ul>
                        </div>
                        </div>
                        
                        {/* <div className="col-lg-4 col-md-6">
                        <div className="footer_widget pe-lg-3">
                            <h2 className="footer_info_title">Newsletter</h2>
                            <p>
                            Sign up to Techco weekly newsletter to get the latest updates.
                            </p>
                            <form className="footer_newslatter_2" onSubmit={SubmitHandler}>
                            <label htmlFor="footer_mail_input">
                                <img src={icon2} alt="Mail SVG Icon" />
                            </label>
                            <input id="footer_mail_input" type="email" name="email" placeholder="Enter your email" />
                            <button type="submit">Send</button>
                            </form>
                        </div>
                        </div> */}
                    </div>
                    </div>
                </div>
                <div className="footer_bottom" style={{ backgroundImage: `url(${Bg2})`, marginTop:'30px' }}>
                    <div className="container d-md-flex align-items-md-center justify-content-md-between">
                    <p className="copyright_text m-0">
                        Copyright © 2011-2024 El Roble – Industrial Manufacturing Services ®, All rights reserved.
                    </p>
                    {/* <ul className="icon_list unordered_list">
                        <li>
                        <Link onClick={ClickHandler} to="/contact">
                            <span className="icon_list_icon">
                            <i className="fa-solid fa-circle"></i>
                            </span>
                            <span className="icon_list_text">
                            Terms of Up
                            </span>
                        </Link>
                        </li>
                        <li>
                        <Link onClick={ClickHandler} to="/contact">
                            <span className="icon_list_icon">
                            <i className="fa-solid fa-circle"></i>
                            </span>
                            <span className="icon_list_text">
                            Privacy Policy
                            </span>
                        </Link>
                        </li>
                    </ul> */}
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Widgets