import pImg1 from '../images/portfolio/portfolio_item_image_1.webp'
import pImg2 from '../images/portfolio/portfolio_item_image_2.webp'
import pImg3 from '../images/portfolio/portfolio_item_image_3.webp'

import pImg4 from '../images/img/laser-cutting-precision-roble-USA.webp'
import pImg5 from '../images/img/waterjet-roble-USA.webp'
import pImg6 from '../images/img/powder-coating-services-roble-USA.webp'
import pImg7 from '../images/img/cnc-machining-services-turning-milling-drilling-roble-USA.webp'
import pImg8 from '../images/img/welding-and-assembly-fabrication-roble-USA.webp'

import pImg9 from '../images/portfolio/portfolio_item_image_6.webp'
import pImg10 from '../images/portfolio/portfolio_item_image_7.webp'
import pImg11 from '../images/portfolio/portfolio_item_image_8.webp'
import pImg12 from '../images/portfolio/portfolio_item_image_9.webp'
import pImg13 from '../images/portfolio/portfolio_item_image_10.webp'
import pImg14 from '../images/portfolio/portfolio_item_image_11.webp'


import tImg1 from '../images/case/icon_angular.svg'
import tImg2 from '../images/case/icon_elephent.svg'
import tImg3 from '../images/case/icon_netcore.svg'
import tImg4 from '../images/case/vuejs.svg'
import tImg5 from '../images/case/icon_python.svg'


const Project = [
    {
        Id: '1',
        pImg: pImg1,
        title: 'Mobile App Design',
        slug: 'Mobile-App-Design',
        sub: 'App Design',
        description: 'Our all-encompassing IELTS Coaching curriculum encompasses every aspect...',
    },
    {
        Id: '2',
        pImg: pImg2,
        title: 'TOEFL Coaching',
        slug: 'TOEFL-Coaching',
        sub: '',
        description: 'Our experienced coaches are not just educators; they are partners in your journey..',
    },
    {
        Id: '3',
        pImg: pImg3,
        title: 'Dashboard Design',
        slug: 'Dashboard-Design',
        sub: 'Web Design',
        description: 'The contents of the citizenship test typically cover a range of subjects ...',
    },
    {
        Id: '4',
        pImg: pImg1,
        title: 'Mobile App Design',
        slug: 'Mobile-App-Design',
        sub: 'App Design',
        description: 'Our all-encompassing IELTS Coaching curriculum encompasses every aspect...',
    },
    {
        Id: '5',
        pImg: pImg4,
        title: 'Laser Cutting',
        color:'#ff6831',
        slug: 'Metal Cutting Solutions',
        sub: 'Metal Cutting Solutions',
        Industry: 'Manufacturing, Technology, Construction, Automation, Mobile Solutions, etc.',
        Country: 'Mexico, USA',
        Technologies1: 'Laser Cutting, Tube Cutting',
        Technologies2: tImg2,
        description: 'Our high-precision laser cutting services are designed for steel, stainless steel, and aluminum, with cutting capacities up to 10’ x 5’ (3050 x 1525 mm) and power up to 12,000 W. We also specialize in tube cutting for more complex geometries, ensuring accuracy and superior finishes in every project',
    },
    {
        Id: '6',
        pImg: pImg5,
        title: 'Waterjet Cutting',
        color:'#203cfe',
        slug: 'Versatile Cutting Technology',
        sub: 'Versatile Cutting Technologye',
        Industry: 'Manufacturing, Technology, Construction, Automation, Mobile Solutions, etc.',
        Country: 'Mexico, USA',
        Technologies1: 'Waterjet Cutting',
        Technologies2: tImg2,
        description: 'Our waterjet cutting system handles a wide range of materials, with the capability to cut up to 8” thick. The large cutting area of 12’ x 7’ (3683 x 2159 mm) allows us to accommodate sizable projects, while ensuring clean, precise cuts without affecting material properties.',
    },
    {
        Id: '7',
        pImg: pImg6,
        title: 'Powder Coating & Painting',
        color: '#00cf2c',
        slug: 'Surface Finishing Solutions',
        sub: 'Surface Finishing Solutions',
        Industry: 'Manufacturing, Technology, Construction, Automation, Mobile Solutions, etc.',
        Country: 'Mexico, USA',
        Technologies1: 'Powder Coating, Automated Finishing',
        Technologies2: tImg3,
        description: 'Our state-of-the-art powder coating line offers a monthly capacity of up to 172,220 ft². Multiple washing stages and automated drying ovens ensure optimal finishes, delivering durable, uniform coatings that enhance both aesthetics and protection.',
    },
    {
        Id: '8',
        pImg: pImg7,
        title: 'CNC Machining & Turning',
        color: '#2a2b33',
        slug: 'Precision Engineering',
        sub: 'Precision Engineering',
        Industry: 'Manufacturing, Technology, Construction, Automation,  Automotive, Aviation, Mobile Solutions, etc.',
        Country: 'Mexico, USA',
        Technologies1: 'CNC Machining',
        Technologies2: tImg3,
        description: 'With advanced CNC machines, we offer precise turning, milling, and drilling services. Our capabilities include tube bending, sheet metal punching, and profile cutting, ensuring we meet the exact specifications of each project with unmatched precision.',
    },
    {
        Id: '9',
        pImg: pImg8,
        title: 'Welding & Assembly',
        color: '#f7d133',
        slug: 'Fabrication & Assembly',
        sub: 'Fabrication & Assembly',
        Industry: 'Manufacturing, Technology, Construction, Automation,  Automotive, Aviation, Mobile Solutions, etc.',
        Country: 'Mexico, USA',
        Technologies1: 'Welding TIG, Welding MIG, Spot Welding, Robotic Welding, Assembly',
        Technologies2: tImg5,
        description: 'We combine robotic welding with manual MIG, TIG and Spot Welding processes to provide the highest quality welding services. Our assembly line includes a dedicated space for quality control, ensuring that every product meets rigorous standards before packaging and delivery.',
    },
    {
        Id: '10',
        pImg: pImg9,
        title: 'Driving Digital Transformation Explore the Depth of Our IT Projects',
        slug: 'Driving-Digital-Transformation-Explore-the-Depth-of-Our-IT Projects',
        thumb1: 'Logo Design',
        thumb2: 'Finance',
        category: 'technology',
    },
    {
        Id: '17',
        pImg: pImg9,
        title: 'Explore Our IT Solutions Portfolio for Public Sector Organizations',
        slug: 'Explore-Our-IT-Solutions',
        thumb1: 'App Design',
        thumb2: 'Public',
        category:'helpdesk'
    },
    {
        Id: '18',
        pImg: pImg9,
        title: 'Innovative Solutions Showcase the Diversity of Our IT Portfolio',
        slug: 'Innovative-Solutions-Showcase-the-Diversity-of-Our-IT-Portfolio',
        thumb1: 'Card Design',
        thumb2: 'Transpiration',
        category:'analysis'
    },
    {
        Id: '19',
        pImg: pImg10,
        title: 'Tech Triumphs Celebrating Our Achievements in IT Solutions',
        slug: 'Tech-Triumphs-Celebrating-Our-Achievements-in-IT-Solutions',
        thumb1: 'Web Design',
        thumb2: 'Logistic',
        category: 'technology',
    },
    {
        Id: '20',
        pImg: pImg11,
        title: 'Revolutionizing IT Strategies A Closer Look at Our Dynamic IT Solutions',
        slug: 'Revolutionizing-IT-Strategies-A-Closer-Look-at-Our-Dynamic-IT-Solutions',
        thumb1: 'Web Design',
        thumb2: 'Fution',
        category: 'analysis',
    },
    {
        Id: '21',
        pImg: pImg12,
        title: 'Cloud Migration and Integration Project IT Solutions Portfolio',
        slug: 'Cloud-Migration-and-Integration-Project-IT-Solutions-Portfolio',
        thumb1: 'Web Design',
        thumb2: 'Energy',
        category: 'helpdesk'
    },
    {
        Id: '22',
        pImg: pImg13,
        title: 'Pioneering Progress Exploring the Evolution and Impact of',
        slug: 'Pioneering-Progress-Exploring-the-Evolution-and-Impact-of',
        thumb1: 'Web Design',
        thumb2: 'Health',
        category: 'marketing'
    },
    {
        Id: '23',
        pImg: pImg14,
        title: 'Unlocking Potential Explore Our Comprehensive IT Portfolio',
        slug: 'Unlocking-Potential-Explore-Our-Comprehensive-IT-Portfolio',
        thumb1: 'Web Design',
        thumb2: 'Industry',
        category: 'technology',
    },

]

export default Project;