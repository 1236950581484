import React, { useState } from "react";
import Bg from '../images/shapes/shape_title_under_line.svg'
import shape1 from '../images/shapes/shape_line_5.svg'
import shape2 from '../images/shapes/shape_line_6.svg'
import shape3 from '../images/shapes/shape_space_5.svg'
import axios from "axios";

function Contact() {
    const [formData, setFormData] = useState({
        name: '',
        company: '',
        state: '',
        phone: '',
        email: '',
        service: '',
        hearAboutUs: '',
        projectDescription: '',
        materialQuantity: '',
        file: null,
    });

    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    
    const handleFileChange = (e) => {
        setFormData({
            ...formData,
            file: e.target.files[0], // Guardamos el archivo en el estado
        });
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Validate required fields
        if (formData.service === "" || formData.hearAboutUs === "" || formData.state === "") {
            alert("Please ensure all fields are filled correctly:\n- Select a valid service\n- Let us know how you heard about us\n- Select a valid state");
            return;
        }
    
        const formDataToSend = new FormData();
        for (const key in formData) {
            formDataToSend.append(key, formData[key]);
        }
    
        // Log the form data before sending
        console.log('Sending form data:', formDataToSend);
    
        try {
            const response = await axios.post('https://roblemx.com/backend/send_email.php', formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
    
            // Log the response from the server
            console.log('Response from server:', response.data);
            const responseData = response.data;
    
            if (response.data.success) {
                alert(responseData.message);
                setFormData({
                    name: '',
                    company: '',
                    state: '',
                    phone: '',
                    email: '',
                    service: '',
                    hearAboutUs: '',
                    projectDescription: '',
                    materialQuantity: '',
                    file: null,
                });
            } else {
                alert(`Error al enviar el correo: ${responseData.error}`); // You might want to log more details about the error here as well
                console.error('Error details:', response.data);
            }
        } catch (error) {
            console.error('Error al enviar el formulario', error);
            alert('Ocurrió un error, intenta nuevamente.');
    
            // Log more details about the error response if available
            if (error.response) {
                console.error('Error response:', error.response.data);
            } else if (error.request) {
                console.error('Error request:', error.request);
            } else {
                console.error('Error message:', error.message);
            }
        }
    };
    
    return (
        <div>
            <section className="contact_section pb-80 bg-light section_decoration" id='contact'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="contact_method_box">
                                <div className="heading_block">
                                    <div className="heading_focus_text has_underline d-inline-flex mb-3" style={{ backgroundImage: `url(${Bg})` }}>
                                        You Are Here
                                    </div>
                                    <h2 className="heading_text mb-0">
                                        Let's Start
                                    </h2>
                                    <p className="heading_description mb-0">Initiating Your Journey to Success and Growth.</p>
                                </div>
                                <ul className="contact_method_list unordered_list_block">
                                    <li>
                                        <a href="tel:+17132833067">
                                            <span className="icon">
                                                <i className="fa-solid fa-phone-volume"></i>
                                            </span>
                                            <span className="text">USA: +1 713 283-3067</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="tel:+525556481436">
                                            <span className="icon">
                                                <i className="fa-solid fa-phone-volume"></i>
                                            </span>
                                            <span className="text">MEX: +52 55 5648-1436</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="mailto:salesusa@roblemx.com">
                                            <span className="icon">
                                                <i className="fa-solid fa-envelope"></i>
                                            </span>
                                            <span className="text">salesusa@roblemx.com</span>
                                        </a>
                                    </li>
                                    {/* <li>
                                        <a href="#!">
                                            <span className="icon">
                                                <i className="fa-solid fa-location-dot"></i>
                                            </span>
                                            <span className="text">Sunshine Business Park</span>
                                        </a>
                                    </li> */}
                                </ul>
                                <ul className="support_step unordered_list_block">
                                    <li>
                                        <span className="serial_number">01</span>
                                        <span className="text">Share your requirements.</span>
                                    </li>
                                    <li>
                                        <span className="serial_number">02</span>
                                        <span className="text">Talk to our experts.</span>
                                    </li>
                                    <li>
                                        <span className="serial_number">03</span>
                                        <span className="text">Get a free quote.</span>
                                    </li>
                                    <li>
                                        <span className="serial_number">04</span>
                                        <span className="text">Start the project.</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="instant_contact_form">
                                <div className="small_title">
                                    <i className="fa-solid fa-envelope-open-text"></i>
                                    Let's Connect!
                                </div>
                                <h3 className="form_title">
                                    Send us a message, and we'll promptly discuss your project with you.
                                </h3>
                                <form onSubmit={handleSubmit} className="xb-item--form contact-form">
                                    <div className="row">
                                        <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="input_title">
                                            <i className="fa-regular fa-user"></i>
                                            </label>
                                            <input
                                            type="text"
                                            name="name"
                                            className="form-control"
                                            placeholder="Your Name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            required
                                            />
                                        </div>
                                        </div>

                                        <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="input_title">
                                            <i className="fa-regular fa-building"></i>
                                            </label>
                                            <input
                                            type="text"
                                            name="company"
                                            className="form-control"
                                            placeholder="Your Company Name"
                                            value={formData.company}
                                            onChange={handleChange}
                                            required
                                            />
                                        </div>
                                        </div>

                                        <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="input_title">
                                            <i className="fa-regular fa-flag-usa"></i>
                                            </label>
                                            <select
                                                name="state"
                                                className="form-select"
                                                value={formData.state}
                                                onChange={handleChange}
                                                required
                                            >
                                                <option value="">State</option>
                                                <option value="Alabama">Alabama</option>
                                                <option value="Alaska">Alaska</option>
                                                <option value="Arizona">Arizona</option>
                                                <option value="Arkansas">Arkansas</option>
                                                <option value="California">California</option>
                                                <option value="Colorado">Colorado</option>
                                                <option value="Connecticut">Connecticut</option>
                                                <option value="Delaware">Delaware</option>
                                                <option value="Florida">Florida</option>
                                                <option value="Georgia">Georgia</option>
                                                <option value="Hawaii">Hawaii</option>
                                                <option value="Idaho">Idaho</option>
                                                <option value="Illinois">Illinois</option>
                                                <option value="Indiana">Indiana</option>
                                                <option value="Iowa">Iowa</option>
                                                <option value="Kansas">Kansas</option>
                                                <option value="Kentucky">Kentucky</option>
                                                <option value="Louisiana">Louisiana</option>
                                                <option value="Maine">Maine</option>
                                                <option value="Maryland">Maryland</option>
                                                <option value="Massachusetts">Massachusetts</option>
                                                <option value="Michigan">Michigan</option>
                                                <option value="Minnesota">Minnesota</option>
                                                <option value="Mississippi">Mississippi</option>
                                                <option value="Missouri">Missouri</option>
                                                <option value="Montana">Montana</option>
                                                <option value="Nebraska">Nebraska</option>
                                                <option value="Nevada">Nevada</option>
                                                <option value="New Hampshire">New Hampshire</option>
                                                <option value="New Jersey">New Jersey</option>
                                                <option value="New Mexico">New Mexico</option>
                                                <option value="New York">New York</option>
                                                <option value="North Carolina">North Carolina</option>
                                                <option value="North Dakota">North Dakota</option>
                                                <option value="Ohio">Ohio</option>
                                                <option value="Oklahoma">Oklahoma</option>
                                                <option value="Oregon">Oregon</option>
                                                <option value="Pennsylvania">Pennsylvania</option>
                                                <option value="Rhode Island">Rhode Island</option>
                                                <option value="South Carolina">South Carolina</option>
                                                <option value="South Dakota">South Dakota</option>
                                                <option value="Tennessee">Tennessee</option>
                                                <option value="Texas">Texas</option>
                                                <option value="Utah">Utah</option>
                                                <option value="Vermont">Vermont</option>
                                                <option value="Virginia">Virginia</option>
                                                <option value="Washington">Washington</option>
                                                <option value="West Virginia">West Virginia</option>
                                                <option value="Wisconsin">Wisconsin</option>
                                                <option value="Wyoming">Wyoming</option>
                                            </select>

                                        </div>
                                        </div>

                                        <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="input_title">
                                            <i className="fa-regular fa-phone-volume"></i>
                                            </label>
                                            <input
                                            type="tel"
                                            name="phone"
                                            className="form-control"
                                            placeholder="Your Phone No."
                                            value={formData.phone}
                                            onChange={handleChange}
                                            required
                                            />
                                        </div>
                                        </div>

                                        <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="input_title">
                                            <i className="fa-regular fa-envelope"></i>
                                            </label>
                                            <input
                                            type="email"
                                            name="email"
                                            className="form-control"
                                            placeholder="Your Email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            required
                                            />
                                        </div>
                                        </div>

                                        <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="input_title">
                                            <i className="fa-regular fa-handshake"></i>
                                            </label>
                                            <select
                                                name="service"
                                                className="form-select"
                                                value={formData.service}
                                                onChange={handleChange}
                                                required
                                            >
                                                <option value="">What service are you interested in?</option>
                                                <option value="Laser Cutting">Laser Cutting</option>
                                                <option value="Tube Laser Cutting">Tube Laser Cutting</option>
                                                <option value="Waterjet Cutting">Waterjet Cutting</option>
                                                <option value="Industrial Machining">Industrial Machining</option>
                                                <option value="Welding">Welding</option>
                                                <option value="Powder coating">Powder coating</option>
                                                <option value="Plate Punching">Plate Punching</option>
                                                <option value="Rolling">Rolling</option>
                                                <option value="Shearing">Shearing</option>
                                                <option value="Threading">Threading</option>
                                            </select>

                                        </div>
                                        </div>

                                        <div className="col-12">
                                        <div className="form-group">
                                            <label className="input_title">
                                            <i className="fa-regular fa-ear-listen"></i>
                                            </label>
                                            <select
                                                name="hearAboutUs"
                                                className="form-select"
                                                value={formData.hearAboutUs}
                                                onChange={handleChange}
                                                required
                                            >
                                                <option value="">How did you hear about us?</option>
                                                <option value="Online ad">Online ad</option>
                                                <option value="Google search">Google search</option>
                                                <option value="Recommendation">Recommendation</option>
                                                <option value="Email">Email</option>
                                                <option value="Other">Other</option>
                                            </select>

                                        </div>
                                        </div>

                                        <div className="col-12">
                                        <div className="form-group">
                                            <label className="input_title">
                                            <i className="fa-regular fa-comments"></i>
                                            </label>
                                            <textarea
                                            name="projectDescription"
                                            className="form-control"
                                            placeholder="Describe your project"
                                            value={formData.projectDescription}
                                            onChange={handleChange}
                                            required
                                            />
                                        </div>
                                        </div>

                                        <div className="col-12">
                                        <div className="form-group">
                                            <label className="input_title">
                                            <i className="fa-regular fa-recycle"></i>
                                            </label>
                                            <input
                                            type="text"
                                            name="materialQuantity"
                                            className="form-control"
                                            placeholder="Type of material and quantity"
                                            value={formData.materialQuantity}
                                            onChange={handleChange}
                                            required
                                            />
                                        </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="input_title">
                                                    
                                                </label>
                                                
                                                <input
                                                    type="file"
                                                    id="fileInput"
                                                    className="form-control"
                                                    onChange={handleFileChange}
                                                    style={{ display: 'none' }} 
                                                />
                                                <button type="button" className="btn btn-primary" style={{paddingBottom:'10px', paddingTop:'10px'}} onClick={() => document.getElementById('fileInput').click()}>
                                                    <i className="fa-regular fa-paperclip"></i> Upload
                                                </button>
                                                <span style={{marginLeft:'20px'}}>
                                                    {formData.file ? formData.file.name : "No attachments"}
                                                </span>
                                            </div>

                                        </div>

                                        <div className="col-12">
                                        <button type="submit" className="btn btn-primary" style={{float:'right'}}>
                                            <span className="btn_label" data-text="Send Request">Send Request</span>
                                            <span className="btn_icon">
                                            <i className="fa-solid fa-arrow-up-right"></i>
                                            </span>
                                        </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="decoration_item shape_image_1">
                    <img src={shape1} alt="Techco Shape" />
                </div>
                <div className="decoration_item shape_image_2">
                    <img src={shape2} alt="Techco Shape" />
                </div>
                {/* <div className="decoration_item shape_image_3">
                    <img src={shape3} alt="Techco Shape" />
                </div> */}
            </section>
        </div>
    )
}

export default Contact