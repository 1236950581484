import React from 'react'
import { Link } from 'react-router-dom';
import Bg from '../images/backgrounds/bg_image_2.webp'
import Bg2 from '../images/shapes/shape_bg_1.webp'
import Bg3 from '../images/shapes/shape_title_under_line.svg'
import Bg4 from '../images/shapes/shape_title_under_line.svg'
import Bg5 from '../images/backgrounds/bg_image-roble.webp'
import Bg6 from '../images/backgrounds/bg_image-roble-2.webp'
import about1 from '../images/about/roble-services-CNC-USA_02.gif'
import about2 from '../images/about/roble-USA.webp'
import about3 from '../images/about/about-roble-USA.webp'
import aIcon1 from '../images/avatar/avatar_1.webp'
import aIcon2 from '../images/avatar/avatar_2.webp'
import aIcon3 from '../images/avatar/avatar_3.webp'
import aIcon4 from '../images/icons/icon_global.svg'
import aIcon5 from '../images/shapes/shape_line.webp'
import shape1 from '../images/shapes/shape_space_2.svg'
import Project from '../api/project'
import PolicySection from '../components/PolicySection/PolicySection';

export function About() {
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <div>
            <section className="about_section section_space">
                <div className="container">
                    <div className="row align-items-center justify-content-lg-between">
                        <div className="col-lg-6">
                            <div className="about_image_1">
                                <img src={about1} alt="Techco - About" />
                                <img src={about2} data-parallax='{"y" : 80, "smoothness": 6}' alt="Techco - About" />
                                {/* <img src={about3} data-parallax='{"y" : -80, "smoothness": 6}' alt="Techco - About" /> */}
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="about_content">
                                <div className="heading_block">
                                    <div className="heading_focus_text">
                                        Our Mission
                                    </div>
                                    <h2 className="heading_text">
                                        Innovative Material  <mark>Transformation Solutions</mark>
                                    </h2>
                                    <p className="heading_description mb-0">
                                        At El Roble, we are dedicated to providing advanced, precision-driven solutions, including laser cutting, waterjet cutting, welding, powder coating, and CNC machining. With a 114,150 ft² facility and a skilled team, we ensure top-tier quality for projects of any size.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <PolicySection/>

            <section className="about_and_case_section section_space section_decoration" id='about' style={{ backgroundImage: `url(${Bg6})`, paddingTop:'0px' }}>
                <div className="container">
                    <div className="pt-170" id='services'>
                    <div className="heading_block text-center text-dark">
                        <div className="heading_focus_text has_underline d-inline-flex" style={{ backgroundImage: `url(${Bg3})` }}>
                            Cutting-Edge Solutions for Every Need
                        </div>
                        <h2 className="heading_text mb-0">
                        <mark>Precision</mark>, Innovation, and Unmatched <mark>Quality</mark> <br/> in Every Project

                        </h2>
                    </div>

                    <div className="case_studies_wrapper">
                        {Project.slice(4, 9).map((project, prj) => (
                        <div className="case_study_block" key={prj}>
                            <div className="case_study_image">
                            <img src={project.pImg} alt="Techco - Cases" />
                            </div>
                            <div className="case_study_content">
                            <ul className="category_list unordered_list text-uppercase">
                                <li><a href="#">{project.sub}</a></li>
                            </ul>
                            <h3 className="case_title">
                                <Link onClick={ClickHandler} to="/" style={{ color: project.color }}>{project.title}</Link>
                            </h3>
                            <p>
                                {project.description}
                            </p>
                            <ul className="icon_list unordered_list">
                                <li>
                                <span className="icon_list_text">
                                    <strong className="text-dark">Industry:</strong> {project.Industry}
                                </span>
                                </li>
                                <li>
                                <span className="icon_list_text">
                                    <strong className="text-dark">Country:</strong> {project.Country}
                                </span>
                                </li>
                                <li>
                                <span className="icon_list_text">
                                    <strong className="text-dark">Core Technologies:</strong> {project.Technologies1}
                                </span>
                                </li>
                            </ul>
                            {/* <Link onClick={ClickHandler} to={`/portfolio_details/${project.slug}`} className="btn btn-primary">
                                <span className="btn_label" data-text="Read Case">Read Case</span>
                                <span className="btn_icon">
                                <i className="fa-solid fa-arrow-up-right"></i>
                                </span>
                            </Link> */}
                            </div>
                        </div>
                        ))}
                    </div>

                    {/* <div className="btns_group pb-0">
                        <Link onClick={ClickHandler} to="/portfolio" className="btn btn-primary">
                        <span className="btn_label" data-text="View More Cases Study">View More Cases Study</span>
                        <span className="btn_icon">
                            <i className="fa-solid fa-arrow-up-right"></i>
                        </span>
                        </Link>
                    </div> */}
                    </div>
                </div>
                {/* <div className="decoration_item shape_image_1">
                    <img src={shape1} alt="Techco Shape" />
                </div> */}
            </section>
        </div>
    )
}

export default About