import React from 'react';
import sIcon1 from '../../images/icons/icon_user_check.svg'
import sIcon2 from '../../images/icons/icon_headphone.svg'
import sIcon3 from '../../images/icons/icon_dollar.svg'

const Policy = [
    {
        title: 'Expertise You Can Trust',
        subTitle: 'Skilled Team & Cutting-Edge Facility',
        icon: sIcon1,
    },
    {
        title: 'Proven Track Record',
        subTitle: 'Delivering Quality, Time After Time',
        icon: sIcon2,
    },
    {
        title: 'Expanding Internationally',
        subTitle: 'Building Stronger Ties in the USA',
        icon: sIcon3,
    },


]


const PolicySection = (props) => {

    return (
        <section className="policy_section" style={{paddingBottom:'60px'}}>
            <div className="container">
                <div className="row">
                    {Policy.map((policy, pitem) => (
                        <div className="col-lg-4" key={pitem}>
                            <div className="iconbox_block layout_icon_left">
                                <div className="iconbox_icon">
                                    <img src={policy.icon} alt="Dollar SVG Icon" />
                                </div>
                                <div className="iconbox_content">
                                    <h3 className="iconbox_title">{policy.title}</h3>
                                    <p className="mb-0">
                                        {policy.subTitle}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default PolicySection;