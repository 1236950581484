import React from 'react'
import { Link } from 'react-router-dom';
import Bg from '../images/shapes/shape_title_under_line.svg'
import circle1 from '../images/hero/circle_engine_1.4.webp'
import circle2 from '../images/hero/circle_engine_2.webp'
import circle3 from '../images/hero/circle_engine_3.9.webp'
import circle4 from '../images/hero/circle_engine_4.webp'
import shape1 from '../images/hero/shape_image_1.8c7463c1eab865ee149d.webp'
import shape2 from '../images/hero/shape_image_2.cb5480aa3142b32f3077.webp'
import shape3 from '../images/hero/shape_image_3.ef64cd77e2d47a683564.webp'
import shape4 from '../images/hero/shape_image_4.7cff8112270c84d346dd.webp'
import Project from '../api/project'

import About from './About';
import Process from './Process';
import Contact from './Contact';


function Home() {
    return (
        <div>
            <section className="software_company_hero_section xb-hidden">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="content_wrap" style={{marginTop:'60px'}}>
                                <div className="heading_focus_text has_underline text-white d-inline-flex" style={{ backgroundImage: `url(${Bg})` }}>
                                    Complete Industrial Solutions 
                                </div>
                                <h1 className="text-white">
                                    Empowering Businesses with <mark> Laser Cutting, Waterjet Precision</mark>, Welding, Powder Coating, and CNC Machining.
                                </h1>
                                <p>
                                In today's fast-paced industrial landscape, flexibility and precision are key. We handle complete projects that involve a combination of our services to deliver exceptional quality, from start to finish.
                                </p>
                                <ul className="step_list text-white unordered_list_block">
                                    <li>Tailored projects for every industry</li>
                                    <li>Expertise in advanced technology</li>
                                </ul>
                                <ul className="btns_group unordered_list p-0 justify-content-start">
                                    <li>
                                        <a className="btn" href="#contact">
                                            <span className="btn_label" data-text="GET A QUOTE">GET A QUOTE</span>
                                            <span className="btn_icon">
                                                <i className="fa-solid fa-arrow-up-right"></i>
                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a className="hotline_block" href="tel:+17132833067">
                                            <span className="hotline_icon">
                                                <i className="fa-solid fa-phone-volume"></i>
                                            </span>
                                            <span className="hotline_content">
                                                <small>CONTACT US DAILY</small>
                                                <strong className="text-white">1 (713) 283-3067</strong>
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="engine_image">
                                <div className="image_wrap_1">
                                    <img src={circle1} alt="Engine" />
                                </div>
                                <div className="image_wrap_2">
                                    <img src={circle2} alt="Engine" />
                                </div>
                                <div className="image_wrap_3">
                                    <img src={circle3} alt="Engine" />
                                </div>
                                <div className="image_wrap_4">
                                    <img src={circle4} alt="Engine" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="shape_image_1">
                    <img src={shape1} alt="Engine" />
                </div>
                <div className="shape_image_2">
                    <img src={shape2} alt="Engine" />
                </div>
                <div className="shape_image_3">
                    <img src={shape3} alt="Engine" />
                </div>
                <div className="shape_image_4">
                    <img src={shape4} alt="Engine" />
                </div>
            </section>

            <About/>
            <Process/>
            <Contact/>
        </div>
    )
}

export default Home