
import React, { useState } from 'react';
import Bg from '../images/shapes/shape_title_under_line.svg'
import shape1 from '../images/shapes/shape_line_2.svg'
import shape2 from '../images/shapes/shape_line_3.svg'
import shape3 from '../images/shapes/shape_line_4.svg'
import shape4 from '../images/shapes/shape_space_3.svg'



import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';

function Process() {

    const [open, setOpen] = useState('1');

    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };

    const [activeTab, setActiveTab] = useState('1');

    const toggletwo = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }


    return (
        <div>
            <section className="process_technology_review_section bg-light section_decoration" id='working'>
                <div className="container">
                    <div className="row align-items-center justify-content-lg-between">
                        <div className="col-lg-6">
                            <div className="heading_block">
                                <div className="heading_focus_text has_underline d-inline-flex" style={{ backgroundImage: `url(${Bg})` }}>
                                    Working Process
                                </div>
                                <h2 className="heading_text mb-0">
                                    Our <mark>Approach</mark>
                                </h2>
                            </div>
                            <Accordion open={open} toggle={toggle} className="accordion" id="service_process_faq">
                                <AccordionItem className="accordion-item">
                                    <AccordionHeader targetId="1">
                                    Step 1: Project Review
                                    </AccordionHeader>
                                    <AccordionBody accordionId="1" className='acc_body'>
                                        <p className="m-0">
                                        We start by reviewing the engineering files provided by the client to assess the project’s viability. This includes determining the appropriate cutting or transformation processes needed to achieve the optimal results.
                                        </p>
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem className="accordion-item">
                                    <AccordionHeader targetId="2">
                                    Step 2: Material & Timeline Analysis
                                    </AccordionHeader>
                                    <AccordionBody accordionId="2" className='acc_body'>
                                        <p className="m-0">
                                        Our team analyzes the materials, delivery times, and customer-submitted files to ensure all details are considered. This step is crucial to accurately estimate production timelines and costs.
                                        </p>
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem className="accordion-item">
                                    <AccordionHeader targetId="3">
                                    Step 3: Quotation
                                    </AccordionHeader>
                                    <AccordionBody accordionId="3" className='acc_body'>
                                        <p className="m-0">
                                        Once all elements are thoroughly reviewed and no questions remain, we proceed with a customized quotation. This ensures transparency in pricing, considering the specific needs of each project.
                                        </p>
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem className="accordion-item">
                                    <AccordionHeader targetId="4">
                                    Step 4: Purchase Order & Project Start
                                    </AccordionHeader>
                                    <AccordionBody accordionId="4" className='acc_body'>
                                        <p className="m-0">
                                        Upon receiving the purchase order, we begin the project according to the client's exact specifications. Our team closely follows the agreed-upon processes to ensure quality and accuracy at every stage.
                                        </p>
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem className="accordion-item">
                                    <AccordionHeader targetId="5">
                                    Step 5: Delivery of Final Product
                                    </AccordionHeader>
                                    <AccordionBody accordionId="5" className='acc_body'>
                                        <p className="m-0">
                                        The final step is the delivery of the finished product or piece. We ensure the project meets all standards before securely packaging and shipping to the client, guaranteeing satisfaction with the completed work.
                                        </p>
                                    </AccordionBody>
                                </AccordionItem>
                            </Accordion>
                        </div>
                        <div className="col-lg-5">
                            <Accordion open={open} toggle={toggle} className="accordion" id="service_process_faq">
                                <ul className="content_layer_group unordered_list_block text-center">
                                    <li><AccordionHeader targetId="1"><span>Project Review</span></AccordionHeader></li>
                                    <li><AccordionHeader targetId="2"><span>Material & Timeline Analysis</span></AccordionHeader></li>
                                    <li><AccordionHeader targetId="3"><span>Quotation</span></AccordionHeader></li>
                                    <li><AccordionHeader targetId="4"><span>Purchase Order & Project Start</span></AccordionHeader></li>
                                    <li><AccordionHeader targetId="5"><span>Delivery of Final Product</span></AccordionHeader></li>
                                </ul>
                            </Accordion>
                        </div>
                    </div>
                </div>

                <div className="decoration_item shape_image_1">
                    <img src={shape1} alt="Techco Shape" />
                </div>
                <div className="decoration_item shape_image_2">
                    <img src={shape2} alt="Techco Shape" />
                </div>
                <div className="decoration_item shape_image_3">
                    <img src={shape3} alt="Techco Shape" />
                </div>
            </section>
        </div>
    )
}

export default Process