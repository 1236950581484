import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Header, Footer } from './Widgets/Widgets';
import Home from './secciones/Home';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
          <main className="page_content">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/industrial-manufacturing-services-texas" element={<Home />} />
            </Routes>
          </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
